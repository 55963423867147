import {
  Alert, Box, Button, Card, CssBaseline, Grid,
  Input, Paper, TextField, Typography,
  Backdrop, CircularProgress, IconButton
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidebar from "../sidebar/sidebar";
import Topbar from "../topbar/topbar";
import { useDispatch, useSelector } from "react-redux";
import {
  saveCompetitionGuidelineSuccess, saveCompetitionGuidelineError, setCompetitionGuidelines,
  getCompetitionGuideline, saveCompetitionGuideline, setTitle, setContent,
  setRegisterPdf, setPoliciePdf, setYouthCatDivisionPdf, setYouthCheerRuleScorePdf,
  setYouthDanceRuleScorePdf, setNaCatDivisionPdf, setNaCheerRuleScorePdf, setNaDanceRuleScorePdf,
  setYacdaMembership, setYacdaVideoLibrary, setErrors
} from "./competitionguidelineSlice";
import { Main } from "../main/main";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Editor from "../../shared/editor/editor";
import { validateString, validateUrl } from "../../shared/validations";
import { Delete } from '@mui/icons-material'

export function CompetitionGuideline() {

  const { REACT_APP_API_URL } = process.env;
  const dispatch = useDispatch();

  const editCompetitionGuidelinemessage = useSelector(
    (state) => state.editCompetitionGuideline.message
  );

  // Attachments
  const [registerPdf, saveRegisterPdf] = useState(null);
  const [policiePdf, savePoliciePdf] = useState(null);
  const [youthcatdivisionPdf, saveYouthCatDivisionPdf] = useState(null);
  const [youthcheerrulescorePdf, saveYouthCheerRuleScorePdf] = useState(null);
  const [youthdancerulescorePdf, saveYouthDanceRuleScorePdf] = useState(null);
  const [nacatdivisionPdf, saveNaCatDivisionPdf] = useState(null);
  const [nacheerrulescorePdf, saveNaCheerRuleScorePdf] = useState(null);
  const [nadancerulescorePdf, saveNaDanceRuleScorePdf] = useState(null);


  const [registerPdfDeleted, saveRegisterPdfDeleted] = useState(false);
  const [policiePdfDeleted, savePoliciePdfDeleted] = useState(false);
  const [youthcatdivisionPdfDeleted, saveYouthCatDivisionPdfDeleted] = useState(false);
  const [youthcheerrulescorePdfDeleted, saveYouthCheerRuleScorePdfDeleted] = useState(false);
  const [youthdancerulescorePdfDeleted, saveYouthDanceRuleScorePdfDeleted] = useState(false);
  const [nacatdivisionPdfDeleted, saveNaCatDivisionPdfDeleted] = useState(false);
  const [nacheerrulescorePdfDeleted, saveNaCheerRuleScorePdfDeleted] = useState(false);
  const [nadancerulescorePdfDeleted, saveNaDanceRuleScorePdfDeleted] = useState(false);

  // PDF Urls
  const registerPdfUrl = useSelector(
    (state) => state.editCompetitionGuideline.data.registerPdfUrl
  );
  const policiePdfUrl = useSelector(
    (state) => state.editCompetitionGuideline.data.policiePdfUrl
  );

  const youthcatdivisionPdfUrl = useSelector(
    (state) => state.editCompetitionGuideline.data.youthcatdivisionPdfUrl
  );

  const youthcheerrulescorePdfUrl = useSelector(
    (state) => state.editCompetitionGuideline.data.youthcheerrulescorePdfUrl
  );

  const youthdancerulescorePdfUrl = useSelector(
    (state) => state.editCompetitionGuideline.data.youthdancerulescorePdfUrl
  );

  const nacatdivisionPdfUrl = useSelector(
    (state) => state.editCompetitionGuideline.data.nacatdivisionPdfUrl
  );

  const nacheerrulescorePdfUrl = useSelector(
    (state) => state.editCompetitionGuideline.data.nacheerrulescorePdfUrl
  );
  const nadancerulescorePdfUrl = useSelector(
    (state) => state.editCompetitionGuideline.data.nadancerulescorePdfUrl
  );

  const mounted = useSelector(
    (state) => state?.editCompetitionGuideline?.mounted
  );
  const loading = useSelector(
    (state) => state?.editCompetitionGuideline.loading
  );
  const severity = useSelector(
    (state) => state.editCompetitionGuideline.severity
  );
  const errors = useSelector((state) => state.editCompetitionGuideline.errors);

  useEffect(() => {
    if (!mounted) {
      dispatch(getCompetitionGuideline());
      fetch(
        `${REACT_APP_API_URL}page/detail/YOUTH_NON_AFFILIATED_ID/YOUTH_NON_AFFILIATED`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === true) {
            dispatch(setCompetitionGuidelines(responseJson.data));
          } else {
            console.log(responseJson);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [REACT_APP_API_URL, dispatch, mounted]);

  const [buttonDisabled, SetButtonDisabled] = useState(false);

  // get pdfName from store
  const registerPdfName = useSelector(
    (state) => state.editCompetitionGuideline.data.registerPdfName
  );
  const policiePdfName = useSelector(
    (state) => state.editCompetitionGuideline.data.policiePdfName
  );
  const youthcatdivisionPdfName = useSelector(
    (state) => state.editCompetitionGuideline.data.youthcatdivisionPdfName
  );

  const youthcheerrulescorePdfName = useSelector(
    (state) => state.editCompetitionGuideline.data.youthcheerrulescorePdfName
  );

  const youthdancerulescorePdfName = useSelector(
    (state) => state.editCompetitionGuideline.data.youthdancerulescorePdfName
  );

  const nacatdivisionPdfName = useSelector(
    (state) => state.editCompetitionGuideline.data.nacatdivisionPdfName
  );

  const nacheerrulescorePdfName = useSelector(
    (state) => state.editCompetitionGuideline.data.nacheerrulescorePdfName
  );

  const nadancerulescorePdfName = useSelector(
    (state) => state.editCompetitionGuideline.data.nadancerulescorePdfName
  );

  const competitionGuidelineData = useSelector(
    (state) => state?.editCompetitionGuideline?.data
  );

  const contentValue = useSelector((state) => state?.editCompetitionGuideline?.data?.contentValue);


  const validateTitle = (e) => {
    const title = e.target.value;
    dispatch(setTitle({ title: title }));
    dispatch(setErrors({ key: 'title', value: validateString(title, 'title') }));

  };

  // on change
  const registerPdfHandleChange = (event) => {
    saveRegisterPdf(event.target.files[0]);
    dispatch(
      setRegisterPdf({
        registerPdfName: event.target.files[0].name,
      })
    );
  };

  // registerPdfHandleDelete
  const registerPdfHandleDelete = (event) => {
    saveRegisterPdf(null);
    saveRegisterPdfDeleted(true);
    dispatch(
      setRegisterPdf({
        registerPdfName: null,
      })
    );
  };

  const policiePdfHandleChange = (event) => {
    savePoliciePdf(event.target.files[0]);
    dispatch(
      setPoliciePdf({
        policiePdfName: event.target.files[0].name,
      })
    );
  };

    // policiePdfHandleDelete
    const policiePdfHandleDelete = (event) => {
      savePoliciePdf(null);
      savePoliciePdfDeleted(true);
      dispatch(
        setPoliciePdf({
          policiePdfName: null,
        })
      );
    };

  const youthcatdivisionPdfHandleChange = (event) => {
    saveYouthCatDivisionPdf(event.target.files[0]);
    dispatch(
      setYouthCatDivisionPdf({
        youthcatdivisionPdfName: event.target.files[0].name,
      })
    );
  };

  const youthcatdivisionPdfHandleDelete = (event) => {
    saveYouthCatDivisionPdf(null);
    saveYouthCatDivisionPdfDeleted(true);
    dispatch(
      setYouthCatDivisionPdf({
        youthcatdivisionPdfName: null,
      })
    );
  };

  const youthcheerrulescorePdfHandleChange = (event) => {
    saveYouthCheerRuleScorePdf(event.target.files[0]);
    dispatch(
      setYouthCheerRuleScorePdf({
        youthcheerrulescorePdfName: event.target.files[0].name,
      })
    );
  };

  const youthcheerrulescorePdfHandleDelete = (event) => {
    saveYouthCheerRuleScorePdf(null);
    saveYouthCheerRuleScorePdfDeleted(true);
    dispatch(
      setYouthCheerRuleScorePdf({
        youthcheerrulescorePdfName: null,
      })
    );
  };

  const youthdancerulescorePdfHandleChange = (event) => {
    saveYouthDanceRuleScorePdf(event.target.files[0]);
    dispatch(
      setYouthDanceRuleScorePdf({
        youthdancerulescorePdfName: event.target.files[0].name,
      })
    );
  };

  const youthdancerulescorePdfHandleDelete = (event) => {
    saveYouthDanceRuleScorePdf(null);
    saveYouthDanceRuleScorePdfDeleted(true);
    dispatch(
      setYouthDanceRuleScorePdf({
        youthdancerulescorePdfName: null,
      })
    );
  };

  const nacatdivisionPdfHandleChange = (event) => {
    saveNaCatDivisionPdf(event.target.files[0]);
    dispatch(
      setNaCatDivisionPdf({
        nacatdivisionPdfName: event.target.files[0].name,
      })
    );
  };

  const nacatdivisionPdfHandleDelete = (event) => {
    saveNaCatDivisionPdf(null);
    saveNaCatDivisionPdfDeleted(true);
    dispatch(
      setNaCatDivisionPdf({
        nacatdivisionPdfName: null,
      })
    );
  };

  const nacheerrulescorePdfHandleChange = (event) => {
    saveNaCheerRuleScorePdf(event.target.files[0]);
    dispatch(
      setNaCheerRuleScorePdf({
        nacheerrulescorePdfName: event.target.files[0].name,
      })
    );
  };

  const nacheerrulescorePdfHandleDelete = (event) => {
    saveNaCheerRuleScorePdf(null);
    saveNaCheerRuleScorePdfDeleted(true);
    dispatch(
      setNaCheerRuleScorePdf({
        nacheerrulescorePdfName: null,
      })
    );
  };

  const nadancerulescorePdfHandleChange = (event) => {
    saveNaDanceRuleScorePdf(event.target.files[0]);
    dispatch(
      setNaDanceRuleScorePdf({
        nadancerulescorePdfName: event.target.files[0].name,
      })
    );
  };

  const nadancerulescorePdfHandleDelete = (event) => {
    saveNaDanceRuleScorePdf(null);
    saveNaDanceRuleScorePdfDeleted(true);
    dispatch(
      setNaDanceRuleScorePdf({
        nadancerulescorePdfName: null,
      })
    );
  };


  const contentHandleChange = (data) => {
    dispatch(
      setContent({
        contentValue: data,
      })
    );
  };

  const yacdamembershipHandleChange = (event) => {
    dispatch(
      setYacdaMembership({
        yacdaMembership: event.target.value,
      })
    );
    dispatch(setErrors({ key: 'ycadamembership', value: validateUrl(event.target.value, 'YCADA Membership') }));
  };

  const yacdavideolibraryHandleChange = (event) => {
    dispatch(
      setYacdaVideoLibrary({
        yacdaVideoLibrary: event.target.value,
      })
    );
    dispatch(setErrors({ key: 'ycadavideolibrary', value: validateUrl(event.target.value, 'YCADA Video Library') }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setErrors({ key: 'title', value: validateString(competitionGuidelineData?.title, 'title') }));
    dispatch(setErrors({ key: 'ycadamembership', value: validateUrl(competitionGuidelineData?.yacdaMembership, 'yacdaMembership') }));
    dispatch(setErrors({ key: 'ycadavideolibrary', value: validateUrl(competitionGuidelineData?.yacdaVideoLibrary, 'yacdaVideoLibrary') }));

    const addPageData = {
      title: competitionGuidelineData?.title,
      content: competitionGuidelineData?.contentValue,
      register_pdf: registerPdf,
      policie_pdf: policiePdf,
      youth_cat_division_pdf: youthcatdivisionPdf,
      youth_cheer_rule_score_pdf: youthcheerrulescorePdf,
      youth_dance_rule_score_pdf: youthdancerulescorePdf,
      na_cat_division_pdf: nacatdivisionPdf,
      na_cheer_rule_score_pdf: nacheerrulescorePdf,
      na_dance_rule_score_pdf: nadancerulescorePdf,
      yacda_membership: competitionGuidelineData?.yacdaMembership,
      yacda_video_library: competitionGuidelineData?.yacdaVideoLibrary,
    };
    
    console.log(addPageData);

    if (errors?.title?.error === '' && errors?.contentValue?.error === '' &&
      errors?.ycadamembership?.error === '' && errors?.ycadavideolibrary?.error === '') {
      sendCompetitionGuidelineRequest(addPageData);
    }
  };

  const sidebarState = useSelector((state) => state.topbar.drawerToggle);

  const sendCompetitionGuidelineRequest = (addPageData) => {

    let formData = new FormData();

    formData.append("title", addPageData.title);

    formData.append("content", addPageData.content);

    formData.append("yacda_membership", addPageData.yacda_membership);
    formData.append("yacda_video_library", addPageData.yacda_video_library);

    if (addPageData.register_pdf) {
      formData.append("register_pdf", addPageData.register_pdf);
    }

    if (addPageData.policie_pdf) {
      formData.append("policie_pdf", addPageData.policie_pdf);
    }

    if (addPageData.youth_cat_division_pdf) {
      formData.append(
        "youth_cat_division_pdf",
        addPageData.youth_cat_division_pdf
      );
    }
    if (addPageData.youth_cheer_rule_score_pdf) {
      formData.append(
        "youth_cheer_rule_score_pdf",
        addPageData.youth_cheer_rule_score_pdf
      );
    }

    if (addPageData.youth_dance_rule_score_pdf) {
      formData.append(
        "youth_dance_rule_score_pdf",
        addPageData.youth_dance_rule_score_pdf
      );
    }

    if (addPageData.na_cat_division_pdf) {
      formData.append("na_cat_division_pdf", addPageData.na_cat_division_pdf);
    }

    if (addPageData.na_cheer_rule_score_pdf) {
      formData.append(
        "na_cheer_rule_score_pdf",
        addPageData.na_cheer_rule_score_pdf
      );
    }

    if (addPageData.na_dance_rule_score_pdf) {
      formData.append(
        "na_dance_rule_score_pdf",
        addPageData.na_dance_rule_score_pdf
      );
    }

    formData.append("registerPdfDeleted", registerPdfDeleted);
    formData.append("policiePdfDeleted", policiePdfDeleted);
    formData.append("youthcatdivisionPdfDeleted", youthcatdivisionPdfDeleted);
    formData.append("youthcheerrulescorePdfDeleted", youthcheerrulescorePdfDeleted);
    formData.append("youthdancerulescorePdfDeleted", youthdancerulescorePdfDeleted);
    formData.append("nacatdivisionPdfDeleted", nacatdivisionPdfDeleted);
    formData.append("nacheerrulescorePdfDeleted", nacheerrulescorePdfDeleted);
    formData.append("nadancerulescorePdfDeleted", nadancerulescorePdfDeleted);


    formData.append("_method", "PUT");

    dispatch(saveCompetitionGuideline());
    fetch(`${REACT_APP_API_URL}page/update/1`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Disposition": "attachment",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(
            saveCompetitionGuidelineSuccess({
              data: responseJson.data,
              message: responseJson.message,
            })
          );
          SetButtonDisabled(false);
        } else {
          console.log(responseJson);
          dispatch(
            saveCompetitionGuidelineError({
              error: responseJson.message,
              addEventButtonDisabled: false,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Sidebar />
      <Main open={sidebarState} className="dashbaordWrapper">
        <Topbar />
        <Paper
          elevation={0}
          sx={{
            background: "#e3f2fd",
            padding: "2.5rem 1.5rem 1.5rem 2.5rem",
            minHeight: "calc(100% - 64px)",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid container spacing={2} item md={12}>
                <Card elevation={1} sx={{ padding: "2rem", width: "100%" }}>
                  <div
                    style={{
                      alignItems: "center",
                      alignContent: "center",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      marginBottom: "1.5rem",
                    }}
                  >
                    <Typography variant="h3">
                      Add / Update Competition Guidelines
                    </Typography>
                  </div>

                  <form
                    onSubmit={(e) => handleSubmit(e)}
                    id="competitionGuidelineForm"
                  >
                    <Grid container spacing={2} item>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                          onChange={(e) => validateTitle(e)}
                          id="title"
                          label="Title *"
                          variant="outlined"
                          fullWidth
                          error={!errors?.title?.isValid}
                          helperText={errors?.title?.error}
                          value={competitionGuidelineData?.title}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          id="yacda_membership_url"
                          label="YCADA MEMBERSHIP URL *"
                          variant="outlined"
                          fullWidth
                          error={!errors?.ycadamembership?.isValid}
                          helperText={errors?.ycadamembership?.error}
                          value={competitionGuidelineData?.yacdaMembership}
                          onChange={yacdamembershipHandleChange}
                        />
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          id="yacda_video_library_url"
                          label="YCADA Video Library URL *"
                          variant="outlined"
                          fullWidth
                          error={!errors?.ycadavideolibrary?.isValid}
                          helperText={errors?.ycadavideolibrary?.error}
                          value={competitionGuidelineData?.yacdaVideoLibrary}
                          onChange={yacdavideolibraryHandleChange}
                        />
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Editor
                          data={contentValue}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            contentHandleChange(data);
                          }} />
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="inputFileWrapper">
                          <label
                            htmlFor="register_pdf"
                            className="file-input pdfInput"
                          >
                            <Input
                              accept="application/pdf"
                              id="register_pdf"
                              onChange={registerPdfHandleChange}
                              inputProps={{ accept: "application/pdf" }}
                              type="file"
                            />
                            <Button
                              color="secondary"
                              variant="outlined"
                              component="span"
                              className="fileInputButton competitionGuidelineInputButton"
                            >
                              How to Register?
                            </Button>

                          </label>
                          <div className="fileName addEvent">
                            <span>
                              <PictureAsPdfIcon />
                            </span>{" "}
                            {registerPdfName
                              ? registerPdfName
                              : "File name will show here"}

                            {registerPdfName &&
                            <div>
                              <a
                                className="editEventPdfDownloadLink"
                                href={registerPdfUrl}
                                rel="noreferrer"
                                target="_blank"
                              >
                                Download
                              </a>
                              <IconButton aria-label="delete"
                                  onClick={() => {
                                    registerPdfHandleDelete();
                                  }}
                              >
                                  <Delete sx={{ color: '#d32f2f' }} />
                              </IconButton>
                              </div>
                            }
                          </div>
                        </div>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="inputFileWrapper">
                          <label
                            htmlFor="policie_pdf"
                            className="file-input pdfInput"
                          >
                            <Input
                              id="policie_pdf"
                              onChange={policiePdfHandleChange}
                              inputProps={{ accept: "application/pdf" }}
                              type="file"
                            />
                            <Button
                              color="secondary"
                              variant="outlined"
                              component="span"
                              className="fileInputButton competitionGuidelineInputButton"
                            >
                              Policies
                            </Button>
                          </label>
                          <div className="fileName addEvent">
                            <span>
                              <PictureAsPdfIcon />
                            </span>{" "}
                            {policiePdfName
                              ? policiePdfName
                              : "File name will show here"}
                            
                            { policiePdfName &&
                            <div>
                            <a
                              className="editEventPdfDownloadLink"
                              href={policiePdfUrl}
                              rel="noreferrer"
                              target="_blank"
                            >
                              Download
                            </a>
                            
                            <IconButton aria-label="delete"
                                onClick={() => {
                                  policiePdfHandleDelete();
                                }}
                            >
                                <Delete sx={{ color: '#d32f2f' }} />
                            </IconButton>
                            </div>
                            }
                          </div>
                        </div>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="inputFileWrapper">
                          <label
                            htmlFor="youth_cat_division_pdf"
                            className="file-input pdfInput"
                          >
                            <Input
                              id="youth_cat_division_pdf"
                              onChange={youthcatdivisionPdfHandleChange}
                              inputProps={{ accept: "application/pdf" }}
                              type="file"
                            />
                            <Button
                              color="secondary"
                              variant="outlined"
                              component="span"
                              className="fileInputButton competitionGuidelineInputButton"
                            >
                              Youth Categories &amp; Divisions
                            </Button>
                          </label>
                          <div className="fileName addEvent">
                            <span>
                              <PictureAsPdfIcon />
                            </span>{" "}
                            {youthcatdivisionPdfName
                              ? youthcatdivisionPdfName
                              : "File name will show here"}

                            {youthcatdivisionPdfName &&
                            <div>
                              <a
                                className="editEventPdfDownloadLink"
                                href={youthcatdivisionPdfUrl}
                                rel="noreferrer"
                                target="_blank"
                              >
                                Download
                              </a>

                              <IconButton aria-label="delete"
                                onClick={() => {
                                  youthcatdivisionPdfHandleDelete();
                                }}
                            >
                                <Delete sx={{ color: '#d32f2f' }} />
                              </IconButton>
                            </div>  
                            }
                          </div>
                        </div>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="inputFileWrapper">
                          <label
                            htmlFor="youth_cheer_rule_score_pdf"
                            className="file-input pdfInput"
                          >
                            <Input
                              id="youth_cheer_rule_score_pdf"
                              onChange={youthcheerrulescorePdfHandleChange}
                              inputProps={{ accept: "application/pdf" }}
                              type="file"
                            />
                            <Button
                              color="secondary"
                              variant="outlined"
                              component="span"
                              className="fileInputButton competitionGuidelineInputButton"
                            >
                              Youth Cheer Rules &amp; Scoring
                            </Button>
                          </label>
                          <div className="fileName addEvent">
                            <span>
                              <PictureAsPdfIcon />
                            </span>{" "}
                            {youthcheerrulescorePdfName
                              ? youthcheerrulescorePdfName
                              : "File name will show here"}

                            {youthcheerrulescorePdfName &&
                            <div>
                              <a
                                className="editEventPdfDownloadLink"
                                href={youthcheerrulescorePdfUrl}
                                rel="noreferrer"
                                target="_blank"
                              >
                                Download
                              </a>

                              <IconButton aria-label="delete"
                                onClick={() => {
                                  youthcheerrulescorePdfHandleDelete();
                                }}
                              >
                                <Delete sx={{ color: '#d32f2f' }} />
                              </IconButton>
                            </div>
                            }
                          </div>
                        </div>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="inputFileWrapper">
                          <label
                            htmlFor="youth_dance_rule_score_pdf"
                            className="file-input pdfInput"
                          >
                            <Input
                              id="youth_dance_rule_score_pdf"
                              onChange={youthdancerulescorePdfHandleChange}
                              inputProps={{ accept: "application/pdf" }}
                              type="file"
                            />
                            <Button
                              color="secondary"
                              variant="outlined"
                              component="span"
                              className="fileInputButton competitionGuidelineInputButton"
                            >
                              Youth Dance Rules &amp; Scoring
                            </Button>
                          </label>
                          <div className="fileName addEvent">
                            <span>
                              <PictureAsPdfIcon />
                            </span>{" "}
                            {youthdancerulescorePdfName
                              ? youthdancerulescorePdfName
                              : "File name will show here"}

                            {youthdancerulescorePdfName && 
                              <div>
                              <a
                                className="editEventPdfDownloadLink"
                                href={youthdancerulescorePdfUrl}
                                rel="noreferrer"
                                target="_blank"
                              >
                                Download
                              </a>

                              <IconButton aria-label="delete"
                                onClick={() => {
                                  youthdancerulescorePdfHandleDelete();
                                }}
                              >
                                <Delete sx={{ color: '#d32f2f' }} />
                              </IconButton>
                              </div>
                            }
                          </div>
                        </div>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="inputFileWrapper">
                          <label
                            htmlFor="na_cat_division_pdf"
                            className="file-input pdfInput"
                          >
                            <Input
                              id="na_cat_division_pdf"
                              onChange={nacatdivisionPdfHandleChange}
                              inputProps={{ accept: "application/pdf" }}
                              type="file"
                            />
                            <Button
                              color="secondary"
                              variant="outlined"
                              component="span"
                              className="fileInputButton competitionGuidelineInputButton"
                            >
                              NA Categories &amp; Divisions
                            </Button>
                          </label>
                          <div className="fileName addEvent">
                            <span>
                              <PictureAsPdfIcon />
                            </span>{" "}
                            {nacatdivisionPdfName
                              ? nacatdivisionPdfName
                              : "File name will show here"}

                            {nacatdivisionPdfName && 
                              <div>
                                <a
                                  className="editEventPdfDownloadLink"
                                  href={nacatdivisionPdfUrl}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  Download
                                </a>
                              
                                <IconButton aria-label="delete"
                                  onClick={() => {
                                    nacatdivisionPdfHandleDelete();
                                  }}
                                >
                                  <Delete sx={{ color: '#d32f2f' }} />
                                </IconButton>

                              </div>
                            }
                          </div>
                        </div>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="inputFileWrapper">
                          <label
                            htmlFor="na_cheer_rule_score_pdf"
                            className="file-input pdfInput"
                          >
                            <Input
                              id="na_cheer_rule_score_pdf"
                              onChange={nacheerrulescorePdfHandleChange}
                              inputProps={{ accept: "application/pdf" }}
                              type="file"
                            />
                            <Button
                              color="secondary"
                              variant="outlined"
                              component="span"
                              className="fileInputButton competitionGuidelineInputButton"
                            >
                              NA Cheer Rules &amp; Scoring
                            </Button>
                          </label>
                          <div className="fileName addEvent">
                            <span>
                              <PictureAsPdfIcon />
                            </span>{" "}
                            {nacheerrulescorePdfName
                              ? nacheerrulescorePdfName
                              : "File name will show here"}

                            {nacheerrulescorePdfName && 
                              <div>
                              <a
                                className="editEventPdfDownloadLink"
                                href={nacheerrulescorePdfUrl}
                                rel="noreferrer"
                                target="_blank"
                              >
                                Download
                              </a>
                              <IconButton aria-label="delete"
                                  onClick={() => {
                                    nacheerrulescorePdfHandleDelete();
                                  }}
                                >
                                  <Delete sx={{ color: '#d32f2f' }} />
                                </IconButton>
                              </div>
                            }
                          </div>
                        </div>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="inputFileWrapper">
                          <label
                            htmlFor="na_dance_rule_score_pdf"
                            className="file-input pdfInput"
                          >
                            <Input
                              id="na_dance_rule_score_pdf"
                              onChange={nadancerulescorePdfHandleChange}
                              inputProps={{ accept: "application/pdf" }}
                              type="file"
                            />
                            <Button
                              color="secondary"
                              variant="outlined"
                              component="span"
                              className="fileInputButton competitionGuidelineInputButton"
                            >
                              NA Dance Rules &amp; Scoring
                            </Button>
                          </label>
                          <div className="fileName addEvent">
                            <span>
                              <PictureAsPdfIcon />
                            </span>{" "}
                            {nadancerulescorePdfName
                              ? nadancerulescorePdfName
                              : "File name will show here"}

                            {nadancerulescorePdfName && 
                            <div>
                              <a
                                className="editEventPdfDownloadLink"
                                href={nadancerulescorePdfUrl}
                                rel="noreferrer"
                                target="_blank"
                              >
                                Download
                              </a>
                              <IconButton aria-label="delete"
                                  onClick={() => {
                                    nadancerulescorePdfHandleDelete();
                                  }}
                                >
                                  <Delete sx={{ color: '#d32f2f' }} />
                                </IconButton>
                              </div>
                            }
                          </div>
                        </div>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Button
                          type="submit"
                          sx={{ margin: "1rem 0" }}
                          disabled={buttonDisabled}
                          variant="contained"
                          disableElevation
                          size="large"
                        >
                          Save Competition Guideline
                        </Button>
                      </Grid>

                      {editCompetitionGuidelinemessage !== "" && (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Alert severity={severity}>
                            {editCompetitionGuidelinemessage}
                          </Alert>
                        </Grid>
                      )}
                    </Grid>
                  </form>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Paper>
      </Main>
    </Box>
  );
}

export default CompetitionGuideline;
